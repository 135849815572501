import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "motionEye on Raspberry Pi",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Motion is a highly configurable LINUX program that monitors video signals from your INSTAR IP cameras. Set it up to monitor security cameras with the home automation system ioBroker. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='motionEye on Raspberry Pi' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Motion is a highly configurable LINUX program that monitors video signals from your INSTAR IP cameras. Set it up to monitor security cameras with the home automation system ioBroker. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/motionEye/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "motioneye-on-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#motioneye-on-raspberry-pi",
        "aria-label": "motioneye on raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`motionEye on Raspberry Pi`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#iobroker-preparation"
        }}>{`ioBroker Preparation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-of-motioneye"
        }}>{`Installation of motionEye`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-ip-camera"
        }}>{`Adding your IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#jpg-camera"
            }}>{`JPG Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mjpg-camera"
            }}>{`MJPG Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#rtsp-camera"
            }}>{`RTSP Camera`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-motioneye-to-iobroker-vis"
        }}>{`Adding motionEye to ioBroker Vis`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iobroker-preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iobroker-preparation",
        "aria-label": "iobroker preparation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Preparation`}</h2>
    <p>{`MotionEye uses the port `}<strong parentName="p">{`8081`}</strong>{` to stream your cameras video. Since this is also the default port for the `}<strong parentName="p">{`ioBroker Admin Panel`}</strong>{` we have to change that beforehand to avoid a clash. Open the Admin Panel and change to the `}<strong parentName="p">{`Instances Tab`}</strong>{`. Click on the wrench icon to edit the panel settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c1e0f98e01762db62733360581907009/e9beb/ioBroker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADNElEQVQoz1WRTWgcZQCG56bgRdBjEQ9erIJ4KQg91CDePGkQQiuJCFKs0ksUERXUCqIlpNpYmhpiETUmzQ9pu2WTwGpDaJJdk/2Z3Zmd2W/n//uZ75uZ3flmdmbTZGTTk8/73l7e0yM8+8KpM+fGJ39d+uiba6+/f+nsD7l3F/2Lq3RsgX64Qs+veJ8tg6v55szf7RuF9mxB/T5vjt7yxubg2ZtAePy50zfzxfz94nYNDI9PvPHV6ge55PP17thyeDHHL9yJLq0q84Xqeql1b7u5UQYzW/DcIn9njgz/ogrCY0++deHLb6/MfDe9cOb8xBez65P/sMsbuFDWNsutooKKCtpV0I4MS5q3u7azu98qAVxS4F4LC088feKpF4deGx0/PfLxS8OfXp5dLSnurkLjKEqiThrzZNCwH3OPB523R/q53GGWHaXxQRIJz5w89fzLrwyNfvLqm++dHBr5+scZ19N1rY0c5lI/DMNuNwy73O/483CpfufPGJi0y7HLqBcImw+K+1WxKgMF6EC3Ncssq1KpAVVV03QTIehAiCAyoXlFn6x01SQ5QsS1HYQwFmzbSXinH3UOHx5kWZakUbUl7UtOWzN1w8HHEExsbE+iqRprJLyPKUOIUOoK927/u7wmL9ytr6wrS2ugslkGRk2V99SWJUsaQtgdQDGGua1rqlHvhj0HEQdiz2NCbn77r6XK/GL591vV6T8qO3cftMEeaN6XZaPRAJZlIwghQtCytN+uo4bo88iB2LIhwVjQmgozjZjgLI2ywzSOO3WlIYuaaUGtbds2omwQRNHPxlWRNtLoAFPmUub7vkBYEPD44OHhUZYdZVnYi8p7W2I+rwBDkoBp2Q4cxHTMKX2qRhu9MMUutSFBhApxL6GeH3TC7JgoCmuKWlQwAKYk63jghFLGXIzUuWlbEoMocSB2EPGDQOgMHHap54c8Oj5zsS5WyiIApmna6BEYY8tOJ274lQaLY4iwg7BLiBDyuJckadpPknRw5mFbqqtyEwALIkQIeSQLQnuj8JNuSCFPEHGdweIK2f+JOceGYWFi6I7rUs9jjDGPeYSRBXpbpi0eRIQyiIhL2X9OwuQazg5qCgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1e0f98e01762db62733360581907009/e4706/ioBroker_01.avif 230w", "/en/static/c1e0f98e01762db62733360581907009/d1af7/ioBroker_01.avif 460w", "/en/static/c1e0f98e01762db62733360581907009/b6582/ioBroker_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c1e0f98e01762db62733360581907009/a0b58/ioBroker_01.webp 230w", "/en/static/c1e0f98e01762db62733360581907009/bc10c/ioBroker_01.webp 460w", "/en/static/c1e0f98e01762db62733360581907009/87ca7/ioBroker_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1e0f98e01762db62733360581907009/81c8e/ioBroker_01.png 230w", "/en/static/c1e0f98e01762db62733360581907009/08a84/ioBroker_01.png 460w", "/en/static/c1e0f98e01762db62733360581907009/e9beb/ioBroker_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c1e0f98e01762db62733360581907009/e9beb/ioBroker_01.png",
              "alt": "ioBroker Admin Panel",
              "title": "ioBroker Admin Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Change the Port to something else:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/e9beb/ioBroker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAChklEQVQoz32QTUgUYRzG30OHqHN06FC38BJYVMciCgTNxCiohPCi+FFUahREIHjoQ8oMNcrShBJTNmxxbf1YVvODxVaXCtZodnZndnZnZ+Z9552P3XVn3t19Y1XIEvrxHP6Xh//zPGBf0dGKmw8Hx2afDDhLr7dfbndXj6ZvTySvOswbLrN+LNXmZIZmg5/9YecSO+Fnns8kqhzpqkHxQl8YgD1FPQ7v6OT8F3/w0p3O8lbHLXfynlu99kltceEml/bIFXrnXf3oi4wssk5fqHtGbHDqjQ6xbogDYMfOYxU1dx90tTzuP9PY0dAzXfkWl/ZKZa+V8/1KxRu5tA+dfoVKetHJF6j4GTzeKZ/oShzukJtGFbBr74Hd+4uPVNYfKqs9WFLX8vS9X6RzETK+ansYe5qxF7isj88ucGSWJZ5QQVMMcf8iPtYAp85dOVt5sfZ+R01za3l1U/fAMKWUENtIWWbaMlNWLksozdIcoTmb5gnNk3yOUEoyaQMIcUmUoIxUI5nO5vK2ZU3Or7wcdiuKIkMkQ5SQC1LgpmSIVARH/OrXEAIMy8cTkgKRZWUopVliL38PujwLKioAIdoOVtESAxkBgs7FVPtcqs1rtnqSze7kh4C+ZmqmaajrYIw3jq1miJCpIU1FYOwbHP+Bpn7qXjYzwdiBqMlFWCYU4jiO56ORCMfzfDgchn9nkGRZURQQi4sYQTuTpjRPKbWtTFRK8LGYUCDG87wQjQpCVJIkeQuiKBbMqmYgzbBsQtfdKcOMBwKY57Gm/YmN8T+1N4qAjGUjrCsI53OFzxkhxo44xGnP+lqbbN9towXAmoF1A2Fd1XRK6ZqmS8srcnBVVhT5v0AIfwMbnny0UWcE/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/e4706/ioBroker_02.avif 230w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/d1af7/ioBroker_02.avif 460w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/b6582/ioBroker_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/a0b58/ioBroker_02.webp 230w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/bc10c/ioBroker_02.webp 460w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/87ca7/ioBroker_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/81c8e/ioBroker_02.png 230w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/08a84/ioBroker_02.png 460w", "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/e9beb/ioBroker_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ace1d35a1316a9bb54db4c3f7fd8703/e9beb/ioBroker_02.png",
              "alt": "ioBroker Admin Panel",
              "title": "ioBroker Admin Panel",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "installation-of-motioneye",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-of-motioneye",
        "aria-label": "installation of motioneye permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation of motionEye`}</h2>
    <p>{`The Software `}<a parentName="p" {...{
        "href": "https://motion-project.github.io/index.html"
      }}>{`MotionEye`}</a>{` can be directly installed under `}<a parentName="p" {...{
        "href": "https://github.com/ccrisan/motioneye/wiki/Installation"
      }}>{`Raspian on your Raspberry Pi`}</a>{`. First we need to install ffmpeg and v4l-utils (might already be installed on your version of Raspbian).`}</p>
    <ol>
      <li parentName="ol">{`Install ffmpeg and v4l-utils`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` ffmpeg v4l-utils`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Then install libmariadbclient18 and libpq5 required by motion:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` libmariadbclient18 libpq5`}</code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Install motion:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` https://github.com/Motion-Project/motion/releases/download/release-4.1.1/pi_stretch_motion_4.1.1-1_armhf.deb

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` dpkg -i pi_stretch_motion_4.1.1-1_armhf.deb`}</code></pre></div>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Install the dependencies from the repositories:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` python-pip python-dev libssl-dev libcurl4-openssl-dev libjpeg-dev libz-dev`}</code></pre></div>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Install motioneye, which will automatically pull Python dependencies (tornado, jinja2, pillow and pycurl):`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` motioneye`}</code></pre></div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Prepare the configuration directory:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /etc/motioneye

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /usr/local/share/motioneye/extra/motioneye.conf.sample /etc/motioneye/motioneye.conf`}</code></pre></div>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Prepare the media directory:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /var/lib/motioneye`}</code></pre></div>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Add an init script, configure it to run at startup and start the motionEye server:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` /usr/local/share/motioneye/extra/motioneye.systemd-unit-local /etc/systemd/system/motioneye.service
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl daemon-reload
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` motioneye
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl start motioneye`}</code></pre></div>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`To upgrade to the newest version of motionEye, just issue:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` motioneye --upgrade
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl restart motioneye`}</code></pre></div>
    <h2 {...{
      "id": "adding-your-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-ip-camera",
        "aria-label": "adding your ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your IP Camera`}</h2>
    <p>{`Open the MotionEye Interface with your web browsers by typing in your Raspberry Pi's IP address followed by the `}<strong parentName="p">{`Port 8765`}</strong>{` -  e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.115:8765`}</code>{`. The default login is `}<strong parentName="p">{`admin`}</strong>{` without a password:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca134d9d274e2952684198873c4c5bd5/e9beb/MotionEye_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABzElEQVQoz4WS0W/SQBzH+6Jt73p37VxcowbaPpXuKGWjXa8FhMIK5QFjYraZbOFFDb64F+Orf44+bIIuE7D+cYaCbiPCPvnmkl9yn/sl9/txh0nfZ6F3wNpx0o67Pgs73d7bd8Ph8P3p2ZLBYNA6jP2gyoKw5OyZhSKlxYK1y/X7L5Jeb3B69uPL1+uLy6vR+OLb6HI0Hn+/+jmdzdJ0lqa/0t+T6ex6Mp1MJx8/fW40Xx4fH8WdDqfsPN1SnynbjwlGBGOZzMEZBJNFFoWiKKqqPvmLpmmcCKAIJBEAQRD4LP+Fz4AQSpKEkAQhRAhxoihkWQI2svIiJ96F5/mVbrfLlcs3siAICCHDMHRd1zQtn89TSi3L0jRN13XDMHK5HABgrUwIse2i53mMsYrnRnHS7vT2D0KXVb2gZu1SCOEmmVIaBEHSTaIoYvWWX2uWKr5T8cteYBasTTJCOAyrjWazXm+E1ZrLarTsUtuhtlMslU2zsEnGGNuOs+96z6MWC8Ky69v9N+7JefD63H71wdxj8NZc7siLOeH5jsjzU5ZlZYts7+BHi6iIkLW//a//cln4LA8f3ETg75FFEYhgTURwr7wEZqsIM9bd+QMP6Gtkw1N4eAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca134d9d274e2952684198873c4c5bd5/e4706/MotionEye_01.avif 230w", "/en/static/ca134d9d274e2952684198873c4c5bd5/d1af7/MotionEye_01.avif 460w", "/en/static/ca134d9d274e2952684198873c4c5bd5/b6582/MotionEye_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca134d9d274e2952684198873c4c5bd5/a0b58/MotionEye_01.webp 230w", "/en/static/ca134d9d274e2952684198873c4c5bd5/bc10c/MotionEye_01.webp 460w", "/en/static/ca134d9d274e2952684198873c4c5bd5/87ca7/MotionEye_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca134d9d274e2952684198873c4c5bd5/81c8e/MotionEye_01.png 230w", "/en/static/ca134d9d274e2952684198873c4c5bd5/08a84/MotionEye_01.png 460w", "/en/static/ca134d9d274e2952684198873c4c5bd5/e9beb/MotionEye_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca134d9d274e2952684198873c4c5bd5/e9beb/MotionEye_01.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Icon in the top left, open the drop-down menu to the right and select to add a camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b151fa316b4c253ed5cba17b0740f4a/e9beb/MotionEye_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtUlEQVQoz4WSy4rUQBSG8xw92Emq6qSqUpckdU93EmeUtgmCMLjTTS8HBF249F3ciO8pCTa2i8x8nEVR8PGfc6qyw/nx9XQ/TW+m+9Nwfjx++DwcD8NwHMfBWqOU0itSypTSPM/n8/l0Os3zfLlcsh/D+29PP79++fX96ffHt590fwixd6H3qeecE4wACBBCMJJCpBRTjH3fe++nacreqSbEMYWxD6MxQbkoWifMQZqIuN4TnhOeg8hJvSf1XQF3V3a7XQZCtFo5a5zrmDvK9IARAqgA46JE/9d6c0PGGFNKO2uNMbxLtWyE7qSJTDb5/lW+3+f5tf6e/5G1bet98HbRKeNLz7rR6UGGCZcFQktUvkFGKeWcM8YZZ1BRUlFCALgALghGCGOE0KYshKivCFHL1qjOq87rzkFFiyLHGG+FZ1pra50xxhprXBBd4KqttRGNhaoiK5vJjPFxHIcVH6JqGoJJRen6thgAyrLclE1nYowhhBijMpG1sVKWNgG4WLrd3tYiex9iiN67ECOXqmI1UAasXgbOXyBDN2CMyQ1F8YKekW3Ksnze35Rh+aLAGHtmYX8ACDiP0D4u70MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b151fa316b4c253ed5cba17b0740f4a/e4706/MotionEye_02.avif 230w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/d1af7/MotionEye_02.avif 460w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/b6582/MotionEye_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b151fa316b4c253ed5cba17b0740f4a/a0b58/MotionEye_02.webp 230w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/bc10c/MotionEye_02.webp 460w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/87ca7/MotionEye_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b151fa316b4c253ed5cba17b0740f4a/81c8e/MotionEye_02.png 230w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/08a84/MotionEye_02.png 460w", "/en/static/4b151fa316b4c253ed5cba17b0740f4a/e9beb/MotionEye_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b151fa316b4c253ed5cba17b0740f4a/e9beb/MotionEye_02.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<strong parentName="p">{`Network Camera`}</strong>{` as Camera Type:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/12fd45f3074e2d95bffe80c585407cf9/e9beb/MotionEye_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB5klEQVQoz32SbWsTQRCA74vmbndudnbvLSXJ1+ZO7SVNvbzcJWkwgdLWF0IqomJBimLVkg+KKIhgBf3bcns5mhTah2HY43hmltkxlF9VjlKOo1w3P1frSkmlQUS7BACklL7G87wgCBqNhkFS0QqZn6VDUhYBAJwxznmeGbMBSOQQUZENIVCUoCBBCpGQFArJARm318Nk3FzDANsWQkgNCAWkmO7GGLOsIgqufeYYtpb1RQSSg4KQpJAOkswLFNdewTZUyzKKtogYBEEURWF0r7ndbLc7aZplg6ybdLebYbMZhWEYdQaCpGlWrmTOOQAwxj3PS5JkPBoNBtns8OjpfD5/fnJwdNjrPkxH404vjdsdIjJN80ou3gMAfN+P47jb72f93tuXH5bLf8vz36/ffNt7dtFffEkmT3buh+KabNs2ESFitRoMh8NH0+l0PP5xenF5fvnn3c/PZ79mZ397i+/pbLEbPxBiUwawXddVStVqtbjV2kv66Xhy8Ph4fz9Lj0/S06+TV58GLz525u93WrskxIZMQq8HkZLKcT3lBcr1pefrgTvgbqFfR6+O7haiYJvzLmQqFofryVmMMw6Mc8usmJU75t0y1nquZFY+ZpFhjdzXP8tg12V+M7qsdQu3yeUK3FjgP3o6UItsdcZOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/12fd45f3074e2d95bffe80c585407cf9/e4706/MotionEye_03.avif 230w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/d1af7/MotionEye_03.avif 460w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/b6582/MotionEye_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/12fd45f3074e2d95bffe80c585407cf9/a0b58/MotionEye_03.webp 230w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/bc10c/MotionEye_03.webp 460w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/87ca7/MotionEye_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/12fd45f3074e2d95bffe80c585407cf9/81c8e/MotionEye_03.png 230w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/08a84/MotionEye_03.png 460w", "/en/static/12fd45f3074e2d95bffe80c585407cf9/e9beb/MotionEye_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/12fd45f3074e2d95bffe80c585407cf9/e9beb/MotionEye_03.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "jpg-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#jpg-camera",
        "aria-label": "jpg camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`JPG Camera`}</h3>
    <p>{`To add your `}<strong parentName="p">{`VGA Camera`}</strong>{` (like IN-3011, IN-2905, etc.) type in your cameras IP Address, prepend the `}<em parentName="p">{`http://`}</em>{` and add a `}<em parentName="p">{`snapshot.cgi`}</em>{`:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/snapshot.cgi`}</code></li>
    </ul>
    <p>{`For HD (like IN-6014 HD, IN-5905 HD, etc.) and Full HD (like IN-8015 FHD, IN-9008 FHD, etc.) camera models add `}<em parentName="p">{`/tmpfs/snap.jpg`}</em>{` for high resolution (`}<em parentName="p">{`your Raspberry Pi might not be fast enough to handle more than one 1080p camera at full resolution`}</em>{`), `}<em parentName="p">{`/tmpfs/auto.jpg`}</em>{` for medium resolution and `}<em parentName="p">{`/tmpfs/auto2.jpg`}</em>{` for low resolution:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/tmpfs/snap.jpg`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/tmpfs/auto.jpg`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/tmpfs/auto2.jpg`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "708px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c2c09a6d9bb709535a136564d5618af/3cb0f/MotionEye_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACM0lEQVQoz2WSW2+bQBBG/WbD3mYvYJLYcU1lTBIMvtXB3E3AdtqXVqqq/oU+Nf//qQIs5dKjfVrNmR3Nfj1h3SpzaA4tZZhCKTEcCfNKcC6EAADyBsaYYRhmi1JqPB73QCjggoumHjgHLrobymA0HnPOMcadTCkVTRmXUjLGAKBHadOStjQVIAhlhPG+hmz7s5QSIdSphOD2NGia1u/3e11jgOZ5KaWyrmWDsIbDO9edTj8ZHUoaQ0tIRdsRdF3XNK2RKaUAwBhzXXe1XPq+7wdBcSjTNMuyIgiWD97CW/jLx+ST84CRzhh7lQE4Y8AY3N/fh2G4D8M4K9Knc16d99lhu093cbFNK98PJpNJ99hFRgjhFkKIM5+H4f6pPBRVXZ6/7aJ0Eya7OAvTw5cwCYL/5G5P3TJns9l6vUmTOEzy4uv3qDxHh/M2Lv1d6u9Lz/M+yhhj1kIp9TwvTdMiz7M8r+rj6XSuj6eyfIqTJM4PURRN7WlXf5F1XedcKCmFEKvV+vn5+VjXp2NdVfWxruuqKooiboiyNLNt+53crpoDgBB87jjBarPZhXlZPe7j5Tb0fvxZ/3rZ/f67+PmyiOvJ6Aa//SpKSfdP0NAmrImmJIQ6c/fqdsrNa2ndCGsklAlwidNFJgwwofgV1C6eDgYDx3EMpXRtQAkmGHfxeCezNsmXhowB55RLxhWibO7ezWYzwzDIG+2drOsaQohzrqRsR+e4DTDS0dxx7BYhxAe/k/8BdnBgHdufIoQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c2c09a6d9bb709535a136564d5618af/e4706/MotionEye_06.avif 230w", "/en/static/0c2c09a6d9bb709535a136564d5618af/d1af7/MotionEye_06.avif 460w", "/en/static/0c2c09a6d9bb709535a136564d5618af/46ffb/MotionEye_06.avif 708w"],
              "sizes": "(max-width: 708px) 100vw, 708px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c2c09a6d9bb709535a136564d5618af/a0b58/MotionEye_06.webp 230w", "/en/static/0c2c09a6d9bb709535a136564d5618af/bc10c/MotionEye_06.webp 460w", "/en/static/0c2c09a6d9bb709535a136564d5618af/3f436/MotionEye_06.webp 708w"],
              "sizes": "(max-width: 708px) 100vw, 708px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c2c09a6d9bb709535a136564d5618af/81c8e/MotionEye_06.png 230w", "/en/static/0c2c09a6d9bb709535a136564d5618af/08a84/MotionEye_06.png 460w", "/en/static/0c2c09a6d9bb709535a136564d5618af/3cb0f/MotionEye_06.png 708w"],
              "sizes": "(max-width: 708px) 100vw, 708px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c2c09a6d9bb709535a136564d5618af/3cb0f/MotionEye_06.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "mjpg-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mjpg-camera",
        "aria-label": "mjpg camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MJPG Camera`}</h3>
    <p>{`To add your `}<strong parentName="p">{`VGA Camera`}</strong>{` (like IN-3011, IN-2905, etc.) type in your cameras IP Address, prepend the `}<em parentName="p">{`http://`}</em>{` and add a `}<em parentName="p">{`videostream.cgi`}</em>{` (you can either add the camera login at the end of the command - as seen below, or use the login mask that motionEye offers)`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/videostream.cgi?user=admin&pwd=instar`}</code></li>
    </ul>
    <p>{`For HD (like IN-6014 HD, IN-5905 HD, etc.) and Full HD (like IN-8015 FHD, IN-9008 FHD, etc.) camera models add `}<em parentName="p">{`/mjpegstream.cgi?-chn=11`}</em>{` for high resolution (`}<em parentName="p">{`your Raspberry Pi might not be fast enough to handle more than one 1080p camera at full resolution`}</em>{`), `}<em parentName="p">{`/mjpegstream.cgi?-chn=12`}</em>{` for medium resolution and `}<em parentName="p">{`/mjpegstream.cgi?-chn=13`}</em>{` for low resolution:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/mjpegstream.cgi?-chn=11&-usr=admin&-pwd=instar`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/mjpegstream.cgi?-chn=12&-usr=admin&-pwd=instar`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/mjpegstream.cgi?-chn=13&-usr=admin&-pwd=instar`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "711px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/a8e5b/MotionEye_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB70lEQVQoz1WSW3OaUBSFfVTObZ/DLWq0CoINVK1CQzggIEdJOradSWf8A31tp///sSOJsfme99pr7UtH9Ee6dWPbN5ZtC8MU9lBYA8G5EAIAyH8wxqwLhmGMx+MOEzoA55wD5wAAXIDQgQvK4PZ2pOs6IYS2MMY4F7zty9viDiWE0Vfa/pxQRhh0NTSZTk3TRAhdvDHG+C1It9vtIIQJoa3l2Z+bdhuBMUad6XQwGHAAIfjrIFxcjGiv1+ucm1HCgBFC3NlssViEYRCGocy3+bYsy916E98FYRgEyyhxwzVGGmXsKoYWQojv+1LKTMqq3h+fT9+eT83xR747FKpJ1XG1id3ZDGN8FSOEcIumaa7rRnGcSZlm26RQSaEiWd7nu7Tcx2mx+rx2Xfcsfov9sidKKcbYcZwojh+bJt9WD4VKtlX0kG+S7G65DqL003LlOs47McaYsfO+CCVzf56maZZJmWVFVVc7VVR1satTmcuizrLc87y2/hJb0zQA0A0DAMIgfHr6ejgclFK1Uk3T7JWqyjKTMs/Ssix930cIXcWUUmg/hDHmeV70JUlSWe8PeVHFSbr+/mtz+nt/+r34+WdZHp3JB3z5mbP45cjsDAhdN0zLsOz+YGhYtj//OJoF5tjrT+fWZG4Nx7r+7s7/ACJ7VTodHqqQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/e4706/MotionEye_05.avif 230w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/d1af7/MotionEye_05.avif 460w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/c3aa3/MotionEye_05.avif 711w"],
              "sizes": "(max-width: 711px) 100vw, 711px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/a0b58/MotionEye_05.webp 230w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/bc10c/MotionEye_05.webp 460w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/68932/MotionEye_05.webp 711w"],
              "sizes": "(max-width: 711px) 100vw, 711px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/81c8e/MotionEye_05.png 230w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/08a84/MotionEye_05.png 460w", "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/a8e5b/MotionEye_05.png 711w"],
              "sizes": "(max-width: 711px) 100vw, 711px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e6b7de1e537b3cddc8a4e377437a5bb0/a8e5b/MotionEye_05.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "rtsp-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rtsp-camera",
        "aria-label": "rtsp camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP Camera`}</h3>
    <p>{`For HD (like IN-6014 HD, IN-5905 HD, etc.) and Full HD (like IN-8015 FHD, IN-9008 FHD, etc.) camera models, prepend the `}<em parentName="p">{`rtsp://`}</em>{` and add `}<em parentName="p">{`/11`}</em>{` for high resolution (`}<em parentName="p">{`your Raspberry Pi might not be fast enough to handle more than one 1080p camera at full resolution`}</em>{`), `}<em parentName="p">{`/12`}</em>{` for medium resolution and `}<em parentName="p">{`/13`}</em>{` for low resolution:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/11`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/12`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.115/13`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e2d396323a6bfa38092791eca2ecf741/e9beb/MotionEye_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABxElEQVQoz1WSXW+bMBSGc9MCtsEfhJgg2jRRpiRbQwgJHwOMwZC2mdabSdMuu///KyZQuyaPXtlXj18dHY8Y95ntMGb3sR3m+mzsMEIYpZQQ8wKMsfsB59z3/REmBOP3EEJJf1BCbcpsTChECMKLIAQAhBCCgREyzcHv1f59OkamBSEyANQM4+ZW13RDM2Af3dANaBjA+GAEIUQI4aF6MuF86k/6i89ms4fZw+Jhzjl3HMcZ25OpZxGq6/qn/G4OtcvlMo7jQxRF0UHWjWyUlHW43we7MNgf9mnp3c+12xsDgM9m3EMQGuQkTtM0F7JsX6runMl2n5bbY7ZNyiDYupxrmnbVjBAyTRMAMJ/PkyQ5nU6FkKlQx1xEaREmeXDMtlG8C0N36l7J1gDGGCG0WCyiKJKy/l6Iou4K2eaVyqqmaE5h3gRB4LrXMgAAY8wYw9har9ZKqaoSQgjZNEqppq6kKKsyr0UhZeV5nqZp4P/MCCFCqT0eU0o2603Tdm13enp+UW2n2lP983d5/pV1r3H7muTCm06vZMuyhr/Ur/r+7m61+fZ1u4uzfBdGq8dwqf5szn8ff7wtn9++BLFjM+1iVf8AihlLTmKkPzcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2d396323a6bfa38092791eca2ecf741/e4706/MotionEye_04.avif 230w", "/en/static/e2d396323a6bfa38092791eca2ecf741/d1af7/MotionEye_04.avif 460w", "/en/static/e2d396323a6bfa38092791eca2ecf741/b6582/MotionEye_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e2d396323a6bfa38092791eca2ecf741/a0b58/MotionEye_04.webp 230w", "/en/static/e2d396323a6bfa38092791eca2ecf741/bc10c/MotionEye_04.webp 460w", "/en/static/e2d396323a6bfa38092791eca2ecf741/87ca7/MotionEye_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2d396323a6bfa38092791eca2ecf741/81c8e/MotionEye_04.png 230w", "/en/static/e2d396323a6bfa38092791eca2ecf741/08a84/MotionEye_04.png 460w", "/en/static/e2d396323a6bfa38092791eca2ecf741/e9beb/MotionEye_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e2d396323a6bfa38092791eca2ecf741/e9beb/MotionEye_04.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`In the example above, we did not need to add the camera login to access the RTSP stream, since we deactivated it in the`}</em>{` `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/#set-the-network-configuration"
        }}>{`Web User Interface`}</a></li>
    </ul>
    <p>{`If your camera does not show up right away. you might have to reboot your Pi.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/e9beb/MotionEye_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADU0lEQVQozyWQW2/bZACG/Se2tomdz/58/Bx/dmwnPsRN0hzsnJeDk2ZttiilzdZmLV03VUARqLtAAgmYiqoiypiEEAxN6yrE6C4QAokbbrnlCokrQNpVfwBqkZ7L95He9yUMv+fVh26xkdIUU1UrJX90c9ztdKrlcuCXWs1mvRykNC2lJ3KeO+h2bywtjYbD5X5/ZTwmRITT2YJu2TISRZ5bGg6PHn/z6Mnps7Mfn56cfnRweGt9ipGc1BNJ44JGs9HqtCu+ryoKwQIw783ruoZEjgdgurn59fOzpz/8/Psff/776tX5+fnJs1Msyo5pWGZC5tlGvR72wiCfpyJRgoNsNrOgapoocgJNr083Pjl+dPjFl189//7s19/++vufFy9eqjJ2koZrmVpc7HQ6mVxGFFgIAMFDNr9QwBqWRA6xbKcbbty9t7P71v577z88+uy7n345+PRYRchO6vmchxVRkPg5cpYB5KXMsvl8SVEVhIQ4xy0u37i//+CDh0eff3tyePz43f0HK6u3EzJyknomn6HoaIScJWkS0hQDAMFCaNl2PI5kJGBRaIe90dra2upksnNvb++d3e2dRlC1DN31PFbmSBCJkHNRKsJBAGmaYBnGMBJxRcKKlJDFQrFcbbZ719r1MFwdjXbvvN7yA8eyFEOjRZqiowxHC3FekriL2hzDOCkTY1mRBV2WKn613QjDWtjtD7bWp29s3u1X67KCoARFRcSGopgYpzRTVyGgCRYytm0oisQzsYQktFvtfm+p3ey3F6/ffm2yNZ60yjUKUinPyRRy9rzj5bxas1YvBxebOcjYli7LgsgBXeKDUpD20s1KJV8sDpqd0eKgWa0VfP9a2PWyaZGHSVPN5eeDQvFSZqFpYJ5nRBakMCoGvu06WddxLbvuB8OwVygV7bRjJhTdiJORK4ahOOlkxnUvDuNZNmWqCPEY8QuuuXV/+82397a3t+5sbkyntz48+LjaarAMibiYqgm6Fi8Wc9VasOC5TAwQkKEtDaeTpikjR1V73c7qZG00vrm8POi2GquTlVzGQ5CRIR0XIBZ4N2lkHTulYjoWIwAAFBWjKIqKxUgqNjc7e/XK1ZmZGQAAwzAUSdE0EwPgfy4zVJQkSYoCAPwHQmfpzLS3MJ0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/e4706/MotionEye_07.avif 230w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/d1af7/MotionEye_07.avif 460w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/b6582/MotionEye_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/a0b58/MotionEye_07.webp 230w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/bc10c/MotionEye_07.webp 460w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/87ca7/MotionEye_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/81c8e/MotionEye_07.png 230w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/08a84/MotionEye_07.png 460w", "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/e9beb/MotionEye_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8f41f0c1dcd2a0ba02970aebdac0eebe/e9beb/MotionEye_07.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-motioneye-to-iobroker-vis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-motioneye-to-iobroker-vis",
        "aria-label": "adding motioneye to iobroker vis permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding motionEye to ioBroker Vis`}</h2>
    <p>{`We already added a JPG stream of our cameras live video to the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/Visualisation/"
      }}>{`ioBroker Visualization`}</a>{` panel. Now we are able to replace that with the motionEye live stream. Open your ioBroker Overview panel and click on Edit Visualization:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/e9beb/MotionEye_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0klEQVQoz02RT4jcZBjGc/LmSb149CIeBVE8CQq9eBFpL7YHQUStQrdSEDx48FhEXaFePIhY223XtjPdEazYVVC7HYTdye5gd2ayM5kkXzL58v1LviST5PtXsrtC3+P7vj+e93le65mXX/9m7c7l23+c/PjLN7/d/bBXnLmO3+umb68nH90Mz9+GK934/AY6t4FPr7PT6/TdDnunw85cQSe+H1pPPvvS1z9uXO5unvz0u4sbw54rr++lt2zSsUnPTn7ZTXp20tvxf92e3dxe3BjQWza9YZPONlm9N7Yee/yJ515547W3Vl44deH3O5v/fP7ZYO2KMUarxhhljBFCNIuBCP7ShWfawVLpxhgzpgvr+VMXXv3g4omVSy++v3rtp+5XTz3dOXuOFqXv+TGElLKyKA7ms60ZQxBACGGCMKVGGxtG1lW7uDasfv5PXn1ghrP4QX/LHY8pZREICcF5zotymYFh4W01zCvKSgrRiFZ5L4msmTunGFfLwhhDMHKmUxCG5pGqG1mEA+Nvau4fNrTS+hgeuwHErFzWxpgEodFoP/ADo6RpKi3qYxjsGO+u5p5RyjSlkuIYjiCiLJOqzQZjMp1OAQA8pZwmnGHOeZpmJJxkwSBDAWeM0yRlrC7K7dCzEGEtLFuYEDx2DgAAUiohlVRKSlVVlev5w30HYyKEqOq6boRR2o6BlWY5y/iRcgTRfScZAcIYjWEM47iqKqPV3//ufrF2dzKdZxmFMK7r/wMryiVLeSNaG3GCdybBAYCUkDzP66pu6lorubO3/0P3T88PMUIoQY2QLQwjK0aEphnPy0PPyBmPAAgeTbtpGt+bA9+bu26/319E0dGZrTJYwIwXSrXpI4TGk0kAwNFLtBTa8xrOXc+bTCaY4NaF0Ytc3XNVH4SWOV7Uh8rYcZwgAHXB65xVYZCuXsr3R34UHTgOxlhIZXRzf5p90kG/Od5Dz2TZct8HrlEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/e4706/MotionEye_08.avif 230w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/d1af7/MotionEye_08.avif 460w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/b6582/MotionEye_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/a0b58/MotionEye_08.webp 230w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/bc10c/MotionEye_08.webp 460w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/87ca7/MotionEye_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/81c8e/MotionEye_08.png 230w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/08a84/MotionEye_08.png 460w", "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/e9beb/MotionEye_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/696dc5f4ebcf40d7b5f700de3c0811f6/e9beb/MotionEye_08.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Drag and drop an `}<strong parentName="p">{`iFrame Widget`}</strong>{` and type in your Raspberry Pi's (the one with motionEye installed) IP address followed by the port `}<strong parentName="p">{`8081`}</strong>{` as source (for camera two choose port `}<strong parentName="p">{`8082`}</strong>{` etc.):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5c4921413c5e51603cb2853fffeac0b/e9beb/MotionEye_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAD/0lEQVQ4y3WTfUzUdRzHf/MfY/J0RyR44yFOMdRoEKApDyJqKJCGPKqRQSAmO1QoLadFtWnLNTc322yttmpra8v+aFnTsMlmNDnl+R7gfsfv7n53x8PhedwDIvBqd5m2Nj/ba9/vX6/v5/P9ft9CZ1c33do+BvSjGMwW9CaJ4REzQ0YRg9mKQbQwaBAZMppDDAYxmOjXjdD1Vw/Xbtzkt+td3LzVS9+wEUE/YkKULJglK7f7BtDe6aN3YBCdcZRBnZ4hvQGjaYwRUcJokjCKEiPiGMZREYvNjuxwYrU7cIxPYjJLCE6Hg3tuN0aDgYyMDNY9v461a9eQlJjIM7GxKBWKh0QTo1QQFRlBcnISV3+/hlWWkR0O7E4HXr+f8ckJhElPgCn/AiaTifj4eCKjoggPD0cQhCcSHhZG5+XL3JMk7MM65KHhEFJ/P4LL48cdmMciSWTnZPPCqlVUVJVTd7iRqvo63mhrpaK2muKtRTQdO0LhxlyKcnMZ0PbwwDODb8pFYPoucx4PnslJhLu++0wH5rFaLBTk55GTlkbLoYM0tjRzsE3DqY73ONZ8kFdLdnD6/Dlq9lRSVljEoMGAZ2aG2bk5/i1fIIDgnnAx63IjWSxkbs7nxTVraKypYe+eCuqb3+SDExrOtGnYW1nOyc8+pelwC7sKt6IfHWU2EGDuP8KA34/Q22fmZ50L148/cTxnA+uzsqgtKeWtpmY07Uf5+psv+PbSBfaV7+bts2c43H6ckk35aLVa3G53CK93Bq/Xy/j4OEJ/j4Ff7shMf/c9H2Zmk59XQMX2Yo6/c4JDrRo+//IiX108T2VpCQ3t7bSePE1x9kv80dlJ/8AAOp2OsbExnE4noigi/Nonc+m6iDzuJLOggM1btrFzYx6aQy0caGjkozPvc+FsB9VlJdQdOYbmVAcvZ2Zz9coVBoeG0Ov1yLL8WNgtTvOnxYvdZiU/N4/N23awJSOHhv311Nbsp2b/axyoqmZfWTkNrUc58vEn7MxaT++tHianph6O7H08cq/VzU2zB5vVwsZNeWzYVEB26jrKd1XySlUtu1+vo3RnCbuLS9lTXUt927tUFm1nQpb5f/mDj/KD1s6FLpkJp4NVqak8HUqHEpVKRVxcPHHxcaEPHxsbS0JCAsuWLQslKHhvwZqfn2dxcTG0D3YpeHwBfLMPsNlspKWlkZCYEErLypVqoqOjSUpKQqFQEBMTQ4o65Z+khIc/Ei4sLDwS+nw+hLszAWbuL2K321m9+jlUqhUooqNJTn6WqKgoEhITUSqVKJVBoZolS5YQGRn5ZOFtaZobIy7sso309HQiIyJYunQparU6tKakqHkqLIzly+NQrVCFOgwe9CTh33ToaJafDsXWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5c4921413c5e51603cb2853fffeac0b/e4706/MotionEye_09.avif 230w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/d1af7/MotionEye_09.avif 460w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/b6582/MotionEye_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5c4921413c5e51603cb2853fffeac0b/a0b58/MotionEye_09.webp 230w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/bc10c/MotionEye_09.webp 460w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/87ca7/MotionEye_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5c4921413c5e51603cb2853fffeac0b/81c8e/MotionEye_09.png 230w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/08a84/MotionEye_09.png 460w", "/en/static/a5c4921413c5e51603cb2853fffeac0b/e9beb/MotionEye_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5c4921413c5e51603cb2853fffeac0b/e9beb/MotionEye_09.png",
              "alt": "Motion Eye with INSTAR IP Cameras",
              "title": "Motion Eye with INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      